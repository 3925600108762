import React, { useState } from "react";

interface IStatement {
  _id: string;
  file1: string;
  file2: string;
  file3: string;
  file4: string;
  file5: string;
  file6: string;
  file7: string;
  file8: string;
  file0: string;
  report: string;
}

export const StatementContext = React.createContext({
  statements: [] as IStatement[],
  setStatements: (i: any) => {},
});

interface StatementContextProviderProps {
  children?: any;
}
export const StatementContextProvider = (props: StatementContextProviderProps) => {
  const [statements, setStatements] = useState([]);

  return <StatementContext.Provider value={{ statements, setStatements }}>{props.children}</StatementContext.Provider>;
};
