import fetch from "isomorphic-fetch";
import axios from "axios";
import join from "url-join";
import { useMemo, useCallback } from "react";

export function useAPI(url: string) {
  const getHeaders = useCallback(() => {
    const baseHeaders = {
      "content-type": "application/json"
    };
    const token = localStorage.getItem("token");
    return token ? { ...baseHeaders, token: token } : baseHeaders;
  }, []);

  const get = useCallback(
    async (endpoint: string, options?: RequestInit) => {
      const res = await fetch(join(url, endpoint), {
        ...options,
        headers: getHeaders()
      });
      return res;
    },
    [url, getHeaders]
  );

  const putFile = useCallback(async (url: string, file: File) => {
    const res = await axios.put(url, file, {
      headers: {
        "content-type": file.type
      }
    });
    return res;
  }, []);

  const post = useCallback(
    async (endpoint: string, body: any) => {
      const res = await fetch(join(url, endpoint), {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(body)
      });
      return res;
    },
    [getHeaders, url]
  );

  const put = useCallback(
    async (endpoint: string, body: any) => {
      const res = await fetch(join(url, endpoint), {
        method: "put",
        headers: getHeaders(),
        body: JSON.stringify(body)
      });
      return res;
    },
    [url, getHeaders]
  );

  const del = useCallback(
    async (endpoint: string) => {
      const res = await fetch(join(url, endpoint), {
        method: "delete",
        headers: getHeaders()
      });
      return res;
    },
    [url, getHeaders]
  );

  return useMemo(() => {
    return {
      get,
      post,
      put,
      del,
      putFile
    };
  }, [get, post, put, del, putFile]);
}
