import { useAPI } from "./api";
import { CONFIG } from "../config";

export function useUpload() {
  const api = useAPI(CONFIG.BACKEND_URL);

  const getUploadLink = async (filename: string, filetype: string) => {
    const res = await api.get(`/signed-url/upload?filename=${filename}`);
    const data = await res.json();
    return { ...data, filename, filetype };
  };

  const getRedirectLink = async (s3key: string) => {
    const res = await api.get(`/signed-url/get?s3key=${s3key}`);
    const data = await res.json();
    return data;
  };

  const uploadToS3 = async (url: string, file: File) => {
    const res = await api.putFile(url, file);
    return res.data;
  };

  const uploadStatement = async (statement: any) => {
    const res = await api.post("/statements", statement);
    return res.ok;
  };

  return { getUploadLink, uploadToS3, uploadStatement, getRedirectLink };
}
