const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const dateUtil = {
  months: [
    { name: "January", num: 0 },
    { name: "February", num: 1 },
    { name: "March", num: 2 },
    { name: "April", num: 3 },
    { name: "May", num: 4 },
    { name: "June", num: 5 },
    { name: "July", num: 6 },
    { name: "August", num: 7 },
    { name: "September", num: 8 },
    { name: "October", num: 9 },
    { name: "November", num: 10 },
    { name: "December", num: 11 },
  ],
  dateFromMs: (ms: any) => {
    return new Date(ms).toLocaleDateString("en-CA", options);
  },
  monthFromDate: (date: Date) => {
    switch (date.getMonth()) {
      case 0:
        return "January";
      case 1:
        return "January";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
    }
  },
};
