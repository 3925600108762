import React, { useRef } from "react";
import { FormEntry } from "./FormEntry";
import { FormErrorMessage } from "./FormErrorMessage";

export interface LoginFormProps {
  onLogin: (username: string, password: string) => any;
  error: string;
}
export function LoginForm(props: LoginFormProps) {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  function login(e: any) {
    e.preventDefault();
    console.log(usernameRef.current?.value);
    props.onLogin(
      usernameRef.current?.value || "",
      passwordRef.current?.value || ""
    );
  }

  return (
    <div className="bg-white p-6 rounded shadow-md">
      <p className="text-gray-700 text-2xl font-bold mb-6">Sign In</p>
      <form onSubmit={login}>
        <FormEntry
          label="Username"
          name="login-username"
          inputRef={usernameRef}
          placeholder="username"
        />
        <FormEntry
          label="Password"
          name="login-password"
          type="password"
          inputRef={passwordRef}
          placeholder="******************"
        />
        <FormErrorMessage message={props.error} />
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 text-sm hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
          {/* <NavLink
            to="/register"
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            type="button"
          >
            Register
          </NavLink> */}
        </div>
      </form>
    </div>
  );
}
