import React, { useState } from "react";
import { LoginForm } from "../forms/LoginForm";
import { useAuth } from "../../services/auth";
import { useHistory } from "react-router-dom";

export function LoginScreen(props: any) {
  const history = useHistory();
  const auth = useAuth();
  const [error, setError] = useState("");

  async function handleLogin(username: string, password: string) {
    try {
      await auth.login(username, password);
      history.push("/");
    } catch (err) {
      console.log(err.statusCode);
      switch (err.statusCode) {
        case 400:
          setError("Invalid username or password");
          break;
        default:
          setError("Internal Server Error");
      }
    }
  }

  return (
    <div className="bg-i3">
      <div className="w-full h-full max-w-md mx-auto flex flex-col justify-center">
        <LoginForm onLogin={handleLogin} error={error} />
        <p className="text-center text-blue-800 text-xs my-3">
          &copy;i3inc All rights reserved.
        </p>
      </div>
    </div>
  );
}
