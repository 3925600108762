import React from "react";
import { useAuth } from "../../services/auth";
import { useHistory, NavLink } from "react-router-dom";

export function Sidebar() {
  const auth = useAuth();
  const history = useHistory();

  function logout() {
    auth.logout();
    history.push("/login");
  }

  return (
    <nav className="flex flex-col fixed bg-white pt-5 w-64 h-full shadow-lg px-6">
      <h1 className="my-5 text-gray-600 text-2xl font-bold select-none">
        Reconciliation
      </h1>
      <ul>
        <li>
          <SidebarNavLink route="/" title="Upload Statement" />
        </li>
        <li>
          <SidebarNavLink route="/statements" title="View Statements" />
        </li>
        <li className="text-gray-800 border-b hover:text-blue-700">
          <SidebarLink onClick={logout}>Logout</SidebarLink>
        </li>
      </ul>
    </nav>
  );
}

function SidebarLink({
  children,
  onClick,
  className = "",
}: {
  children: any;
  onClick?: () => any;
  className?: string;
}) {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer font-semibold text-sm py-4 ${className}`}
    >
      {children}
    </div>
  );
}

interface SidebarNavLinkProps {
  route: string;
  title: string;
}
function SidebarNavLink(props: SidebarNavLinkProps) {
  const { route, title } = props;
  return (
    <div className="text-gray-800 border-b hover:text-blue-700">
      <NavLink exact to={route} activeClassName="text-blue-700">
        <SidebarLink>{title}</SidebarLink>
      </NavLink>
    </div>
  );
}
