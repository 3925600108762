import React from "react";
import { Sidebar } from "../nav/Sidebar";

interface BlankScreenProps {
  children?: any;
}

export function BlankScreen(props: BlankScreenProps) {
  return (
    <div className="flex h-full w-full">
      <Sidebar />
      <div className="py-12 ml-64 flex-1 bg-nav-rev flex flex-col">
        {props.children}
      </div>
    </div>
  );
}
