import React from "react";

export function FormErrorMessage({ message }: { message: string }) {
  return (
    <p
      className={
        message
          ? "my-3 text-red-500 text-md bold font-semibold"
          : "my-3 text-transparent text-md font-semibold"
      }
    >
      {message || "No Error"}
    </p>
  );
}
