import { useAPI } from "./api";
import { CONFIG } from "../config";
import { useContext, useCallback, useMemo } from "react";
import { StatementContext } from "../context/StatementContextProvider";

export function useStatements() {
  const api = useAPI(CONFIG.BACKEND_URL);
  const { statements, setStatements } = useContext(StatementContext);

  const fetchStatements = useCallback(async () => {
    const res = await api.get("/statements");
    if (res.ok) {
      const data = await res.json();
      setStatements(data);
      return data;
    }
  }, [api, setStatements]);

  const deleteStatementById = useCallback(
    async (id: string) => {
      const res = await api.del(`/statements/${id}`);
      if (res.ok) {
        // retrigger fetch
        fetchStatements();
      }
      return res.ok;
    },
    [api, fetchStatements]
  );

  const getStatementById = useCallback(
    async (id: string) => {
      const res = await api.get(`/statements/${id}`);
      const data = await res.json();
      return data;
    },
    [api]
  );

  const reprocessStatement = useCallback(
    async (id: string) => {
      const res = await api.put(`/statements/${id}`, {});
      return res;
    },
    [api]
  );

  return useMemo(() => {
    return {
      fetchStatements,
      getStatementById,
      deleteStatementById,
      reprocessStatement,
      statements
    };
  }, [
    fetchStatements,
    getStatementById,
    deleteStatementById,
    reprocessStatement,
    statements
  ]);
}
