import React from "react";
import "../styles/main.css";
import { LoginScreen } from "./screens/LoginScreen";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./hoc/ProtectedRoute";
import { HomeScreen } from "./screens/HomeScreen";
import { AuthContextProvider } from "../context/AuthContextProvider";
import { StatementsScreen } from "./screens/StatementsScreen";
import { StatementContextProvider } from "../context/StatementContextProvider";
import { StatementDetailScreen } from "./screens/StatementDetailScreen";

function App() {
  return (
    <AuthContextProvider>
      <StatementContextProvider>
        <Router>
          <Switch>
            <ProtectedRoute exact path="/">
              <HomeScreen />
            </ProtectedRoute>

            <ProtectedRoute exact path="/statements">
              <StatementsScreen />
            </ProtectedRoute>

            <ProtectedRoute path="/statements/:id">
              <StatementDetailScreen />
            </ProtectedRoute>

            <Route path="/login">
              <LoginScreen />
            </Route>

            {/* <Route path="/register">
              <RegisterScreen />
            </Route> */}

            <Route path="*">
              <LoginScreen />
            </Route>
          </Switch>
        </Router>
      </StatementContextProvider>
    </AuthContextProvider>
  );
}

export default App;
