import { useAPI } from "./api";
import { useContext } from "react";
import { CONFIG } from "../config";
import { AuthContext } from "../context/AuthContextProvider";

export function useAuth() {
  const api = useAPI(CONFIG.BACKEND_URL);
  const { authenticated, setAuthenticated, user } = useContext(AuthContext);

  const login = async (username: string, password: string) => {
    const res = await api.post("auth/login", {
      username,
      password,
    });

    if (res.ok) {
      const data: { token: string } = await res.json();
      localStorage.setItem("token", data.token);
      setAuthenticated(true);
    } else {
      const err = await res.json();
      throw err;
    }
  };

  const verify = async () => {
    const res = await api.get("auth/verify");
    if (res.ok) {
      const data = await res.json();
      if (data.ok) {
        setAuthenticated(true);
      }
      return data.ok;
    } else {
      return false;
    }
  };

  const register = async (username: string, password: string) => {
    const res = await api.post("auth/register", {
      username,
      password,
    });
    if (!res.ok) {
      const err = await res.json();
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuthenticated(false);
  };

  return { login, logout, verify, register, authenticated, user };
}
