import React, { useEffect, useState } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth } from "../../services/auth";

export const ProtectedRoute = (props: RouteProps) => {
  const { ...rest } = props;
  const { authenticated, verify } = useAuth();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (!authenticated) {
      setLoading(true);
      verify().then((valid) => {
        setValid(valid);
        setLoading(false);
      });
    } else {
      setValid(true);
      setLoading(false);
    }
  }, [verify, authenticated]);

  return loading ? (
    <div>loading...</div>
  ) : (
    <Route {...rest}>
      {valid ? props.children : <Redirect to="/login" />}{" "}
    </Route>
  );
};
