import React, { useEffect } from "react";
import { BlankScreen } from "./BlankScreen";
import { useStatements } from "../../services/statements";
import { useHistory } from "react-router-dom";
import { dateUtil } from "../../utils/date-util";
import check from "../../assets/check.svg";
import error from "../../assets/error.svg";
import timer from "../../assets/timer.svg";
import { useUpload } from "../../services/upload";

export function StatementsScreen() {
  const { fetchStatements, statements } = useStatements();
  const history = useHistory();

  useEffect(() => {
    fetchStatements();
  }, [fetchStatements]);

  const onItemClicked = (id: any) => {
    history.push(history.location.pathname + "/" + id);
  };

  console.log("Rendering Statements Screen");

  return (
    <BlankScreen>
      <div className="container mx-auto overflow-hidden sm:rounded-md h-full px-8 py-6">
        <h3 className="text-lg font-medium text-gray-900 leading-6 mb-3">
          Statements
        </h3>
        {statements?.length > 0 ? (
          <div className="bg-white border-2 shadow-lg">
            <ul>
              {statements.map(el => {
                return (
                  <ListItem
                    statement={el}
                    onClick={onItemClicked}
                    key={el._id}
                  />
                );
              })}
            </ul>
          </div>
        ) : (
          <h1 className="p-6 text-xl text-gray-700">
            No statements found. Click upload to submit a statement.
          </h1>
        )}
      </div>
    </BlankScreen>
  );
}

interface ListItemProps {
  statement: any;
  onClick: (id: any) => any;
}
function ListItem(props: ListItemProps) {
  const statement = props.statement;
  const currentStatus = statement.status[statement.status.length - 1];
  const upload = useUpload();
  const { deleteStatementById } = useStatements();

  const icon = () => {
    switch (currentStatus) {
      case "complete":
        return check;
      case "error":
        return error;
      default:
        return timer;
    }
  };

  const color = () => {
    switch (currentStatus) {
      case "complete":
        return "green";
      case "error":
        return "red";
      default:
        return "yellow";
    }
  };

  async function downloadReport(key: string) {
    const data = await upload.getRedirectLink(key);
    const { url } = data;
    window.open(url);
  }

  return (
    <li className="flex items-center justify-between cursor-pointer sm:px-6 hover:bg-gray-200 transition-colors duration-150 border-b">
      <div
        onClick={() => props.onClick(statement._id)}
        className="flex items-center flex-1 p-4"
      >
        <div className="w-32 text-sm font-medium text-blue-600 truncate leading-5 flex-1">
          {dateUtil.monthFromDate(new Date(statement.date))?.toUpperCase()}
          {"-"}
          {new Date(statement.date).getFullYear()}
        </div>
        <div className="w-64 text-sm text-gray-900 leading-5 flex-1">
          Uploaded on&nbsp;
          <time dateTime="2020-01-07">
            {dateUtil.dateFromMs(statement.createdAt)}
          </time>
        </div>
        <div className="flex items-center w-32 mt-2 flex-1">
          <img src={icon()} height="20" width="20" alt="statement-status" />
          <p
            className={`ml-2 text-sm text-${color()}-600 bg-${color()}-100 px-4 rounded-full border border-${color()}-300`}
          >
            {currentStatus}
          </p>
        </div>
      </div>
      <button
        className="px-2 py-1 text-sm font-semibold text-white bg-blue-500 rounded shadow hover:bg-blue-600 focus:shadow-outline z-10 mr-3"
        onClick={() => {
          statement.report && downloadReport(statement.report);
        }}
      >
        Download Report
      </button>
      <button
        className="px-2 py-1 text-sm font-semibold text-white bg-red-500 rounded shadow hover:bg-red-600 focus:shadow-outline z-10"
        onClick={() => {
          deleteStatementById(statement._id);
        }}
      >
        Delete
      </button>
    </li>
  );
}
