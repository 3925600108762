import React, { useState } from "react";
import { FileSubmit } from "../forms/FileSubmit";
import { useUpload } from "../../services/upload";
import { UploadSuccess } from "../modals/UploadSuccess";
import { BlankScreen } from "./BlankScreen";

export function HomeScreen() {
  const upload = useUpload();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(
    filemap: Map<string, File | undefined | null>,
    date: string
  ) {
    const statement: any = {};
    console.log(date);
    setLoading(true);

    for (let value of filemap.values()) {
      if (!value) {
        setLoading(false);
        return alert("All files must be filled out.");
      }
    }

    const s3links = await Promise.all(
      Array.from(filemap, ([key, val]) => {
        return upload.getUploadLink(val?.name || "", key);
      })
    );

    await Promise.all(
      s3links.map(val => {
        const file: File | undefined | null = filemap.get(val.filetype) as File;
        statement[val.filetype] = val.key;
        return upload.uploadToS3(val.url, file);
      })
    );

    const uploadComplete = await upload.uploadStatement({ ...statement, date });

    if (uploadComplete) {
      setLoading(false);
      setShowModal(true);
    }
  }

  return (
    <BlankScreen>
      <div>
        <UploadSuccess show={showModal} onClose={() => setShowModal(false)} />
        {!loading ? (
          <FileSubmit onSubmit={handleSubmit} />
        ) : (
          <div className="container mx-auto">
            <h1>Uploading Please Wait...</h1>
          </div>
        )}
      </div>
    </BlankScreen>
  );
}
