import React from "react";

interface FormEntryProps {
  name: string;
  inputRef: any;
  label: string;
  type?: string;
  placeholder?: string;
}

export function FormEntry({
  name,
  inputRef,
  label,
  placeholder,
  type = "text",
}: FormEntryProps) {
  return (
    <div className="mb-4 my-auto">
      <label
        htmlFor={name}
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        {label}
      </label>
      <input
        ref={inputRef}
        name={name}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
}
